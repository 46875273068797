




import { Component, Vue } from "vue-property-decorator"

@Component({
  name: "NewsPage"
})
export default class NewsPage extends Vue {
  mounted() {
    if (this.$store.state.menus.activities !== "ACTIVE") {
      this.$router.push(`/?lang=${localStorage.getItem("lang") || "th"}`)
    }
  }
}
